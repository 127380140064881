/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const SVG = styled.svg`
	transition: fill 0.4s;
`;

/* Main Component */
const Logo = (props) => {
	const width = props.width || "225";
	const height = props.height || "59";
	const viewBox = `0 0 ${width} ${height}`;
	// const fill = props.dark ? "#000000" : "#ffffff";
	return (
		<SVG
			width={width}
			height={height}
			viewBox={viewBox}
			fill="#ffffff"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M153.388 17.9136L159.802 29.2987H167.167L160.198 17.0379C163.287 15.5251 165.266 12.4201 165.266 8.99662C165.266 4.06042 161.307 0 156.318 0H141.431V29.2987H148.083V17.9136H153.388ZM148.003 12.2609V5.65275H155.13C157.268 5.73236 158.694 7.00622 158.694 8.91701C158.694 10.8278 157.189 12.1017 154.972 12.1813H148.003V12.2609Z" />
			<path d="M114.507 29.2987V0H136.284V5.57313H121.317V11.4647H134.383V17.1971H121.317V23.4071H136.996V29.2987H114.507Z" />
			<path d="M69.6068 17.9136L76.0211 29.2987H83.3857L76.4171 17.0379C79.5054 15.5251 81.4851 12.4201 81.4851 8.99662C81.4851 4.06042 77.5257 0 72.5368 0H57.7285V29.2987H64.3804V17.9136H69.6068ZM64.222 12.2609V5.65275H71.349C73.4871 5.73236 74.9125 7.00622 74.9125 8.91701C74.9125 10.8278 73.4079 12.1017 71.1906 12.1813H64.222V12.2609Z" />
			<path d="M40.0697 30.0153C48.0678 30.0153 52.8191 25.3976 52.8191 17.6748V0H46.0088V17.1971C46.0088 21.3372 43.8708 23.7256 40.0697 23.7256C36.2686 23.7256 34.1305 21.3372 34.1305 17.1971V0H27.3203V17.6748C27.3203 25.3976 32.0716 30.0153 40.0697 30.0153Z" />
			<path d="M105.005 13.057C108.964 14.4105 110.865 17.7544 110.865 21.0187C110.865 25.5568 107.222 29.2987 102.629 29.2987H86.5537V0H101.362C105.242 0 108.331 3.18464 108.331 7.08584C108.331 9.55394 107.064 11.7832 105.005 13.057ZM93.2056 23.8052H100.649C103.025 23.8052 104.213 22.0537 104.213 20.3021C104.213 18.869 103.104 16.9582 100.649 16.9582H93.2056V23.8052ZM98.6696 11.5443C100.412 11.5443 101.758 10.1909 101.758 8.51893C101.758 6.84699 100.333 5.49351 98.6696 5.49351C98.5904 5.49351 98.0361 5.49351 93.2056 5.49351V11.5443C93.3639 11.5443 95.6604 11.5443 98.6696 11.5443Z" />
			<path d="M182.213 17.9136L188.628 29.2987H195.992L189.024 17.0379C192.112 15.5251 194.092 12.4201 194.092 8.99662C194.092 4.06042 190.132 0 185.143 0H170.256V29.2987H176.908V17.9136H182.213ZM176.829 12.2609V5.65275H183.956C186.094 5.73236 187.519 7.00622 187.519 8.91701C187.519 10.8278 186.014 12.1017 183.797 12.1813H176.829V12.2609Z" />
			<path d="M205.732 29.2987V17.3563L194.171 0H202.644L209.296 10.1112L215.948 0H224.5L212.859 17.3563V29.2987H205.732Z" />
			<path d="M18.451 13.057C22.4104 14.4105 24.3109 17.7544 24.3109 21.0187C24.3109 25.5568 20.6683 29.2987 16.0753 29.2987H0V0H14.8083C18.6885 0 21.7769 3.18464 21.7769 7.08584C21.7769 9.55394 20.5099 11.7832 18.451 13.057ZM6.65185 23.8052H14.0956C16.4713 23.8052 17.6591 22.0537 17.6591 20.3021C17.6591 18.869 16.5504 16.9582 14.0956 16.9582H6.65185V23.8052ZM12.1159 11.5443C13.858 11.5443 15.2042 10.1909 15.2042 8.51893C15.2042 6.84699 13.7788 5.49351 12.1159 5.49351C12.0367 5.49351 11.4824 5.49351 6.65185 5.49351V11.5443C6.81023 11.5443 9.10671 11.5443 12.1159 11.5443Z" />
			<path d="M130.028 49.0436C130.028 43.2316 134.384 39.2508 140.006 39.2508C145.628 39.2508 149.984 43.2316 149.984 49.0436C149.984 54.7759 145.628 58.8363 140.006 58.8363C134.384 58.8363 130.028 54.8555 130.028 49.0436ZM145.549 49.0436C145.549 45.7793 143.411 43.2316 140.085 43.2316C136.759 43.2316 134.542 45.7793 134.542 49.0436C134.542 52.3078 136.68 54.8555 140.085 54.8555C143.49 54.8555 145.549 52.3078 145.549 49.0436Z" />
			<path d="M120.288 51.1932L124.485 58.5975H129.315L124.802 50.6359C126.781 49.6805 128.048 47.6105 128.048 45.3812C128.048 42.1966 125.435 39.5692 122.267 39.5692H112.606V58.5975H116.883V51.1932H120.288ZM116.803 43.2316H121.476C122.901 43.3112 123.772 44.1074 123.772 45.3812C123.772 46.5755 122.822 47.4512 121.396 47.5308H116.883V43.2316H116.803Z" />
			<path d="M109.597 54.7759H99.3817V50.7155H107.934V46.9735H99.3817V43.152H109.122V39.5692H95.0264V58.5975H109.597V54.7759Z" />
			<path d="M74.5166 39.5692H78.9512V47.0531H87.0284V39.5692H91.463V58.5975H87.0284V51.034H78.9512V58.5975H74.5166V39.5692Z" />
		</SVG>
	);
};
export default Logo;
